/*!
 * Pico.css v1.5.2 (https://picocss.com)
 * Copyright 2019-2022 - Licensed under MIT
 */

// Config
@import "~@picocss/pico/scss/variables";

// Theming
// @import "~@picocss/pico/scss/themes/default";
// @import "~@picocss/pico/scss/themes/default/colors";
@import "colors";

// Commons styles
@import "~@picocss/pico/scss/themes/default/styles";

// Light theme (Default)
// Can be forced with data-theme="light"
@import "~@picocss/pico/scss/themes/default/light";

// Dark theme (Auto)
// Automatically enabled if user has Dark mode enabled
@import "~@picocss/pico/scss/themes/default/dark";
// @media only screen and (prefers-color-scheme: dark) {
//   :root:not([data-theme="light"]) {
//     @include dark;
//   }
// }

// Dark theme (Forced)
// Enabled if forced with data-theme="dark"
[data-theme="dark"] {
  @include dark;
}

// Layout
@import "~@picocss/pico/scss/layout/document"; // html
@import "~@picocss/pico/scss/layout/sectioning"; // body, header, main, footer
@import "~@picocss/pico/scss/layout/container"; // .container, .container-fluid
@import "~@picocss/pico/scss/layout/section"; // section
@import "~@picocss/pico/scss/layout/grid"; // .grid
@import "~@picocss/pico/scss/layout/scroller"; // figure

// Content
@import "~@picocss/pico/scss/content/typography"; // a, headings, p, ul, blockquote, ...
@import "~@picocss/pico/scss/content/embedded"; // audio, canvas, iframe, img, svg, video
@import "~@picocss/pico/scss/content/button"; // button, a[role=button], type=button, type=submit ...
@import "~@picocss/pico/scss/content/form"; // input, select, textarea, label, fieldset, legend
@import "~@picocss/pico/scss/content/form-checkbox-radio"; // type=checkbox, type=radio, role=switch
@import "~@picocss/pico/scss/content/form-alt-input-types"; // type=color, type=date, type=file, type=search, ...
@import "~@picocss/pico/scss/content/table"; // table, tr, td, ...
@import "~@picocss/pico/scss/content/code"; // pre, code, ...
@import "~@picocss/pico/scss/content/miscs"; // hr, template, [hidden], dialog, canvas

// Components
@import "~@picocss/pico/scss/components/accordion"; // details, summary
@import "~@picocss/pico/scss/components/card"; // article
@import "~@picocss/pico/scss/components/modal"; // dialog
@import "~@picocss/pico/scss/components/nav"; // nav
@import "~@picocss/pico/scss/components/progress"; // progress
@import "~@picocss/pico/scss/components/dropdown"; // dropdown

// Utilities
@import "~@picocss/pico/scss/utilities/loading"; // aria-busy=true
@import "~@picocss/pico/scss/utilities/tooltip"; // data-tooltip
@import "~@picocss/pico/scss/utilities/accessibility"; // -ms-touch-action, aria-*
@import "~@picocss/pico/scss/utilities/reduce-motion"; // prefers-reduced-motion
