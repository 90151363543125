#home {
  .index {
    height: 2px;
    background-color: var(--card-background-color);

    .bar {
      height: 2px;
      width: 50%;
      background-color: var(--primary);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &.tab-right {
        margin-left: 50%;
      }
    }
  }
}